export const LOADING = 'LOADING';
export const ERROR = 'ERROR';
export const SUCCESS = 'SUCCESS';
export const OPEN_NAV = 'OPEN_NAV';
export const CLOSE_NAV = 'CLOSE_NAV';
export const PIN_NAV = 'PIN_NAV';

//Auth
export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_IN = 'SIGN_IN';
export const SET_IS_AUTH = 'SET_IS_AUTH';
export const VERIFY_ACCOUNT = 'VERIFY_ACCOUNT';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PERMITTED_MODULES = 'GET_PERMITTED_MODULES';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const SEARCH = 'SEARCH';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const REQUEST_OTP = 'REQUEST_OTP';
export const RECEIVE_OTP = 'RECEIVE_OTP';
