const token = localStorage.getItem('token');

export const initialState = {
   auth: {
      token: token,
      user: {},
      search: null,
      isAuth: false,
      loading: false,
      error: null,
      success: null,
      notifications: [],
      isNavOpen: true,
      isNavbarPinned: true,
   },
   humanResources: {
      loading: false,
      error: null,
      success: null,
      employees: { archived: {}, staff: {}, loading: false, error: null, success: null },
      employee: { staff: {}, loading: false, error: null, success: null },
      stations: { locations: [], loading: false, error: null, success: null },
      departments: { dept: [], loading: false, error: null, success: null },
      actions: { actions: [], loading: false, error: null, success: null },
      jobTitles: { jobs: [], loading: false, error: null, success: null },
   },
   sPrime: {
      loading: false,
      error: null,
      success: null,
      flights: { flights_data: [], loading: false, error: null, success: null },
      flight: {
         flight_data: {},
         loading: false,
         create_loading: false,
         error: null,
         success: null,
      },
      airlines: { airline_data: [], airline_loading: false, success: null },
      logs: { logs_data: [], loading: false, error: null, success: null },
      log: { log_data: {}, loading: false, create_loading: false, error: null, success: null },
      reports: { reports_data: [], loading: false, error: null, success: null },
      report: {
         report_data: {},
         loading: false,
         create_loading: false,
         error: null,
         success: null,
      },
      bag: { bag_data: {}, loading: false, create_loading: false, error: null, success: null },
      bags: { bags_data: [], loading: false, error: null, success: null },
   },
};
