import { is } from 'immutable';
import * as type from '../types/types';
import { initialState } from './initialState';
import Cookies from 'js-cookie';

const auth = (state = initialState.auth, action) => {
   switch (action.type) {
      case type.LOADING:
         return {
            ...state,
            loading: action.payload,
         };

      case type.SIGN_OUT:
         localStorage.clear();
         localStorage.removeItem('token');
         Cookies.remove('API_KEY');

         return {
            ...state,
            isAuth: false,
            user: null,
            loading: false,
         };

      case type.SIGN_IN:
         // case type.VERIFY_ACCOUNT:
         localStorage.setItem('token', action.payload.token);
         localStorage.setItem('apiKey', action.payload.apiKey);
         localStorage.setItem('permId', action.payload.permissions);
         localStorage.setItem('user', JSON.stringify(action.payload));
         Cookies.set('API_KEY', action.payload.apiKey, {
            expires: 9 / 24, // 9 hours
            secure: true,
            sameSite: 'None',
         });

         return {
            ...state,
            isAuth: true,
            user: action.payload,
            loading: false,
         };

      case type.RECEIVE_OTP:
         localStorage.setItem('token', action.payload);
         return {
            ...state,
            isAuth: false,
            token: action.payload,
            loading: false,
         };

      case type.REQUEST_OTP:
         localStorage.setItem('staffId', JSON.stringify(action.payload));
         return {
            ...state,
            isAuth: false,
            user: action.payload,
            loading: false,
         };

      case type.ERROR:
         return {
            ...state,
            error: action.payload,
         };

      case type.SUCCESS:
         return {
            ...state,
            success: action.payload,
         };

      case type.SEARCH:
         return {
            ...state,
            search: action.payload,
         };

      case type.GET_PERMITTED_MODULES:
         return {
            ...state,
            permittedModules: action.payload.modules.map((a) => a.section),
            permittedActions: action.payload.actions.map((a) => a.name),
         };

      case type.SET_IS_AUTH:
      case type.GET_PROFILE:
         return {
            ...state,
            isAuth: true,
            token: localStorage.getItem('token'),
            user: action.payload,
            loading: false,
         };

      case type.GET_NOTIFICATIONS:
         return {
            ...state,
            notifications: action.payload,
         };

      case type.CLOSE_NAV:
         return {
            ...state,
            isNavOpen: false,
         };

      case type.OPEN_NAV:
         return {
            ...state,
            isNavOpen: true,
         };

      case type.PIN_NAV:
         return {
            ...state,
            isNavbarPinned: !state.isNavbarPinned,
         };

      default:
         return { ...state };
   }
};

export default auth;
